import { Link } from "react-router-dom";
import Star2Img from "../../../assets/images/v1/star2-orange.png";

import Thumb1Img from "../../../assets/images/v2/h-thumb1.png";
import Thumb2Img from "../../../assets/images/v2/h-thumb2.png";
import Thumb3Img from "../../../assets/images/v2/h-thumb3.png";
import Thumb4Img from "../../../assets/images/v2/h-thumb4.png";
import Thumb5Img from "../../../assets/images/v2/h-thumb5.png";
import BenefitCard from "./BenefitCard";
import Content from "./Content";

const servicesData = [
  {
    id: 1,
    title: "Boost Sales",
    description:
      'Increase customer numbers and order frequency.',
    img: Thumb1Img,
  },
  {
    id: 2,
    title: "Enhance Customer Experience & Loyalty",
    description:
      "Provide convenient and efficient ordering, payment, and pickup options that drive customer loyalty and repeat business.",
    img: Thumb2Img,
  },
  {
    id: 3,
    title: "Reduce Employee Interaction",
    description:
      "Automate tasks such as taking orders and payments, reducing the need for phone orders and in-person interactions.",
    img: Thumb3Img,
  },
  {
    id: 4,
    title: "Instant Online Ordering Platform",
    description:
      "Make your menu available online.",
    img: Thumb4Img,
  },
  {
    id: 5,
    title: "Integrated Online Payment System",
    description:
      "Receive payments directly to your nominated bank account.",
    img: Thumb5Img,
  },
  {
    id: 6,
    title: "Optimize Operations",
    description:
      "Streamline your order management process with automated geo-fence-based notifications and alerts, reducing wait times and improving efficiency.",
    img: Thumb5Img,
  },
  {
    id: 7,
    title: "Insights and Analytics",
    description:
      "Access insights and analytics to track performance, customer behavior, and operational efficiency, helping you make data-driven decisions.",
    img: Thumb5Img,
  },
];

function Benefits() {
  return (
    <div className="section">
      <div className="container" style={{ position: "relative" }}>
        <div className="faq-wrap">
          <div className="row">
            <div className="mb-5">
              <Content />
            </div>
            <div className="mt-3">
              <div className="service-increase-wrap">
                {servicesData.map((service) => (
                  <BenefitCard key={service.id} service={service} />
                ))}
              </div>
            </div>
          </div>
          <div className="text-center">
            <Link className="call-btn" to="https://admin.dishdash.io/">
              Start Your Free Trial  <i className="icon-call"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}



export default Benefits;
