import ServiceCard from "./ServiceCard";
import Star2Img from "../../../assets/images/v1/star2-orange.png";

const servicesData = [
	{
		id: 1,
		title: "Provide Customers With Ultimate Convenience",
		description:
			"Allow your customers to order and pay online, and choose to get their order while in the car, in-store, or at the table.",
		icon: "icon-client-support",
		linkTitle: "Get The Consumer App",
	},
	{
		id: 2,
		title: "Fully Automated System",
		description:
			"Automated geo-fence-based text and sound notifications and alerts.",
		icon: "icon-web",
	},
	{
		id: 3,
		title: "Advanced Reporting and Analytics",
		description:
			'Track performance to identify best-performing items, most valuable customers, and more.',
		icon: "icon-data-analysis",
	},
	{
		id: 4,
		title: "Flexible Order & Pickup Options",
		description:
			'Enable curbside pickup, in-store pickup, phone orders, counter pickup (replacing buzzers), stadium and event orders, or table delivery.',
		icon: "icon-target-1",
	},
	{
		id: 5,
		title: "Flexible Payment Options",
		description:
			'Integrated online payment system supporting online payments (including cards, Google Pay, and Apple Pay) and Upon-Arrival Payments.',
		icon: "icon-design-thinking",
	},
	{
		id: 6,
		title: "Custom Orders",
		description:
			'A 2-way ordering to allow for custom orders, offering special items with dynamic pricing.',
		icon: "icon-content-writing",
	},
];

function Services() {
	return (
		<div className="section section-padding4" style={{ backgroundColor: "#000000", color: "#fdfde1" }}>
			<div className="container">
				<div className="section-title center">
					<h2 style={{ color: "#fdfde1" }}>
						<span>
							Transform Your Business with DishDash
							<img src={Star2Img} alt="StarImg" className="d-none d-lg-inline" width={50} style={{ marginLeft: "20px" }} />
						</span>

					</h2>
				</div>
				<div className="service-wrap">
					<div className="row">
						{servicesData.map((item) => (
							<ServiceCard key={item.id} service={item} />
						))}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Services;
